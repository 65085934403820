import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { animated } from "react-spring";
import { useFade } from "../transitions";
import ImageGrid from "../components/library/ImageGrid";

const heroWrapperStyles = css`
  max-width: 70em;
  margin: auto;
`;

const pageWrapperStyles = css`
  margin: auto;
  padding: 0 16px 16px;
  max-width: 600px;
`;

const AboutPage = ({ data, transitionStatus }) => {
  const fade = useFade(transitionStatus);
  const pageData = data.page.childMarkdownRemark;

  const galleryImages = data.page.childMarkdownRemark.frontmatter.images
    ?.filter((galleryImage) => galleryImage.image)
    .map((galleryImage) => {
      console.log(galleryImage);
      const image = galleryImage.image.childImageSharp.fluid;
      return {
        image,
        caption: galleryImage.caption,
      };
    });

  return (
    <animated.div style={fade}>
      <div css={heroWrapperStyles}>
        {pageData.frontmatter.hero && (
          <Img fluid={pageData.frontmatter.hero.childImageSharp.fluid} />
        )}
      </div>
      <div css={pageWrapperStyles}>
        <h1>{pageData.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: pageData.html }} />

        {galleryImages?.length > 0 ? (
          <div>
            <h2>Gallery</h2>
            <ImageGrid images={galleryImages} />
          </div>
        ) : null}
      </div>
    </animated.div>
  );
};

export const query = graphql`
  query AboutQuery {
    page: file(relativePath: { eq: "about/index.md" }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
          hero {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          images {
            caption
            image {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
